import axiosIns from "@/libs/axios";

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchTeam(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axiosIns
          .get(`/team/?id=${id}`)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    fetchTeams(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axiosIns
          .get("/team/list", { params: queryParams })
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    addTeam(ctx, data) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post("/team", data)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    updateTeam(ctx, data) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post("/team", data)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    deleteTeam(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axiosIns
          .delete(`/team/?id=${id}`)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    fetchUsers(ctx) {
      return new Promise((resolve, reject) => {
        axiosIns
          .get("/options?user=1&admin=1")
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    syncTeamUsers(ctx, data) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post("/team/sync-users", data)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    syncTeamSupervisor(ctx, data) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post("/team/sync-supervisors", data)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
  },
};
